
import { div } from 'react-dom-factories'

focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex]:not([tabindex=-1]), *[contenteditable]"

# vanilla js one here...
# https://bpceee.github.io/posts/5

BsModalClass = createReactClass
  displayName: 'BsModal'

  _backboneForceUpdate: -> @forceUpdate()

  componentDidMount: ->
    R.bs_modal.on 'view_update', @_backboneForceUpdate, @

  getSnapshotBeforeUpdate: (prevProps, prevState) ->
    if not R.bs_modal.attributes.show_modal # modal closed
      return { was_focus: jQuery(':focus') }
    else
      null


  componentDidUpdate: (prevProps, prevState, snapshot) ->
    if snapshot?
      # save current focus
      @_focusedElementBeforeModal = snapshot.was_focus
    if  R.bs_modal.attributes.show_modal # modal open
      if not @dom_ref.matches(':focus-within')
        # focus modal
        @dom_ref.focus()
    else
      # modal probably closed
      @_focusedElementBeforeModal?.focus()
      @_focusedElementBeforeModal = null

  componentWillUnmount: ->
    R.bs_modal.off 'view_update', @_backboneForceUpdate, @

  closeModal: (e) ->
    if e._modal_clicked
      return
    if R.bs_modal.attributes.no_click_close
      toastie.info null, null, raw_message: "Please click <strong>close</strong> or <strong>cancel</strong> or press <kbd class='light'>ESC</kbd> to close modal."
      return
    else
      R.bs_modal.close()

  keydown: (e) ->
    if e.keyCode is 27 # esc
      e.preventDefault()
      R.bs_modal.close()
    else if e.keyCode is 9 #tab
      # get list of all children elements in given object
      o = $(@dom_ref).find('*')
      # get list of focusable items
      focusableItems = o.filter(focusableElementsString).filter(':visible')
      #get currently focused item
      focusedItem = jQuery(':focus')
      # get the number of focusable items
      numberOfFocusableItems = focusableItems.length
      #get the index of the currently focused item
      focusedItemIndex = focusableItems.index(focusedItem)

      if (e.shiftKey)
        # back tab
        # if focused on first item and user preses back-tab, go to the last focusable item
        if (focusedItemIndex == 0)
          focusableItems.get(numberOfFocusableItems - 1).focus()
          e.preventDefault()
      else
        # forward tab
        # if focused on the last item and user preses tab, go to the first focusable item
        if (focusedItemIndex == numberOfFocusableItems - 1)
          focusableItems.get(0).focus()
          e.preventDefault()

  stopClose: (e) ->
    e._modal_clicked = true

  # using a "callback ref"
  save_ref: (dom_ref) ->
    @dom_ref = dom_ref

  render: ->
    if not R.bs_modal.attributes.show_modal
      null
    else
      body = @props.body or R.bs_modal.attributes.body
      #eg. modal-sm modal-lg # modal-xl
      size = @props.size or R.bs_modal.attributes.size or "modal-600" # custom modal-600 size in (rm_)fun_strap.css
      fragment null,
        div { ref: @save_ref, className: 'modal', style: { display: 'block' }, onClick: @closeModal, onKeyDown: @keydown, tabIndex: '-1' },
          div { tabIndex: '-1', className: "modal-dialog #{size}", onClick: @stopClose },
            body
        div { className: "modal-backdrop fade show" }

BsModal = React.createFactory BsModalClass

export default BsModal



